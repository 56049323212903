import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { memo } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material";
import MuiIconButton from "../MuiIconButton";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import BottomMenu from "./BottomMenu";
import MuiTooltip from "../MuiTooltip";
import { en } from "../../locale/en";
import { Link, useLocation } from "react-router-dom";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  alignItems: "center",
  minHeight: 0,
  color: theme.colors.white.primary,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: theme.colors.gray.secondary,
  fontWeight: 400,
  minHeight: 0,
  fontSize: 12,
  lineHeight: "18px",
  padding: 8,
  borderRight: "1px solid",
  borderColor: theme.colors.blueGray.light,
  minWidth: 110,
  ":hover": {
    backgroundColor: theme.colors.gray.light,
  },
  "&.Mui-selected": {
    fontWeight: 500,
    backgroundColor: theme.colors.blue.primary,
    color: theme.colors.white.primary,
    borderRadius: " 0px 0px 10px 0px",
    borderRight: "none",
  },
}));

const BottomTab = ({ items }) => {
  const [showModal, setShowModal] = useState(null);
  const theme = useTheme();
  const { pathname } = useLocation();

  const handleClick = (event) => {
    setShowModal(event.currentTarget);
  };
  const handleClose = () => {
    setShowModal(null);
  };

  const matchPath = (items = []) => {
    const navPath = pathname.split("/")[2] || "";
    const matchItem = items.find(({ path }) => {
      return path === navPath;
    });
    return matchItem?.path || "new-construction";
  };

  const selectedTabValue = matchPath(items);

  const getTabItems = () => {
    return items.map((item) => {
      return (
        <StyledTab
          key={item.path}
          label={item.label}
          disableFocusRipple
          disableRipple
          component={Link}
          value={item.path}
          to={item.path}
        />
      );
    });
  };

  return (
    <Box
      sx={{
        borderTop: 1,
        height: 36,
        borderColor: theme.colors.gray.border,
        backgroundColor: theme.colors.white.primary,
        display: "flex",
        position: "fixed",
        zIndex: 2,
        left: 0,
        bottom: 0,
        right: 0,
        alignItems: "baseline",
      }}
    >
      <MuiTooltip title={en.allTabs} placement={"top"}>
        <MuiIconButton
          onClick={handleClick}
          sx={{
            display: { xs: "none", xxs: "flex" },
            borderRadius: 0,
            height: 34,
            marginTop: "3px",
            borderRight: "1px solid",
            borderColor: theme.colors.blueGray.light,
          }}
        >
          <Icon path={mdiMenu} size={1} color={theme.colors.blueGray.medium} />
        </MuiIconButton>
      </MuiTooltip>
      <StyledTabs
        value={selectedTabValue}
        variant="scrollable"
        scrollButtons={false}
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {getTabItems()}
      </StyledTabs>
      <BottomMenu
        tabItems={items}
        anchorEl={showModal}
        open={Boolean(showModal)}
        handleClose={handleClose}
        value={selectedTabValue}
        sx={{
          "& .MuiMenu-list": {
            color: theme.colors.white.primary,
          },
        }}
      />
    </Box>
  );
};

BottomTab.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(BottomTab);
