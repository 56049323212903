import React, { memo } from "react";
import { Collapse, styled } from "@mui/material";
import MapMarkerInfoWindow from "./MapMarkerInfoWindow";
import MapMarkerSvg from "./MapMarkerSvg";
import { Icon } from "@iconify/react";
import { jobConfirmationStatus } from "../../screens/Schedule/const";
import MapPersonAssignBox from "./MapPersonAssignBox";

const MapMarkerPin = ({
  name,
  color,
  infoImage,
  infoName,
  infoNameShort,
  infoAddress,
  infoDesc,
  infoCity,
  selected = false,
  markerHoverSelected = false,
  ConfirmationStatus,
  userMenuData,
  usersRef,
  jobId,
  onConfirmationStatusChange,
  onClickMenuItem,
  checked,
  setRightClickCheck,
}) => {
  const StyledMarkerContainer = styled("div")(({ theme }) => ({
    position: "relative",
    display: "inline-block",
    "&:hover": {
      zIndex: 99,
      "& .enable-window, & .enable-name": {
        display: "inline-block",
      },
    },
    "&.selected": {
      zIndex: 99,
      "& .enable-window, & .enable-name": {
        display: "inline-block",
      },
    },
  }));

  const StyledMapMarkerCircle = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    cursor: "pointer",
    width: "42px",
    height: "42px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      boxShadow: `0px 0px 0px 1px ${color}`,
      background: theme.colors.white.primary,
    },
    "&.selected-circle": {
      boxShadow: `0px 0px 0px 1px ${color}`,
      background: theme.colors.white.primary,
    },
    "& svg": {
      position: "relative",
      zIndex: 1,
    },
  }));

  const StyledMapMarkerRound = styled("div")(({ theme }) => ({
    margin: 0,
    width: 18,
    height: 18,
    fontSize: 9,
    textAlign: "center",
    lineHeight: "18px",
    fontWeight: 500,
    position: "absolute",
    zIndex: 1,
    color: color,
    top: 7,
  }));

  const StyledMapPersonNameOuter = styled("div")(({ theme }) => ({
    position: "absolute",
    right: "50%",
    top: 47,
    textAlign: "center",
    width: 175,
    marginRight: -87,
    display: "none",
  }));

  const StyledMapPersonName = styled("div")(({ theme }) => ({
    background: color,
    fontSize: "12px",
    padding: "3px 7px",
    color: theme.colors.white.primary,
    borderRadius: "25px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    display: "inline-block",
  }));

  const StyledMapMarkerCompleted = styled("div")(({ theme, statusColor }) => ({
    position: "absolute",
    width: 15,
    height: 15,
    right: 3,
    top: 0,
    background: statusColor,
    border: `1px solid ${theme.colors.white.primary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    color: theme.colors.white.primary,
    borderRadius: "50%",
    fontSize: 10,
  }));
  const getConfirmationStatusColor = (status) => {
    switch (status) {
      case jobConfirmationStatus.HOLD:
        return (
          <StyledMapMarkerCompleted
            statusColor={
              "linear-gradient(101.66deg, #9400EF 0%, #65219A 100.56%)"
            }
          >
            <Icon icon="mdi:hand-front-right" />
          </StyledMapMarkerCompleted>
        );
      case jobConfirmationStatus.RESCHEDULED:
        return (
          <StyledMapMarkerCompleted
            statusColor={
              "linear-gradient(164.2deg, #E02125 7.49%, #89000C 91.68%)"
            }
          >
            <Icon icon="mdi:thumb-down" />
          </StyledMapMarkerCompleted>
        );
      case jobConfirmationStatus.APPROVED:
        return (
          <StyledMapMarkerCompleted
            statusColor={
              "linear-gradient(167.37deg, #30B237 -49.81%, #0F8415 91.02%)"
            }
          >
            <Icon icon="material-symbols:thumb-up" />
          </StyledMapMarkerCompleted>
        );
      default:
        return;
    }
  };

  return (
    <>
      <StyledMarkerContainer className={selected ? "selected" : null}>
        <MapMarkerInfoWindow
          name={infoName}
          image={infoImage}
          address={infoAddress}
          city={infoCity}
          desc={infoDesc}
          className="enable-window"
        />
        <StyledMapMarkerCircle
          className={selected || markerHoverSelected ? "selected-circle" : null}
        >
          <MapMarkerSvg color={color} />
          <StyledMapMarkerRound>{infoNameShort}</StyledMapMarkerRound>
          {getConfirmationStatusColor(ConfirmationStatus)}
        </StyledMapMarkerCircle>
        <StyledMapPersonNameOuter className="enable-name">
          <StyledMapPersonName>{name}</StyledMapPersonName>
        </StyledMapPersonNameOuter>
      </StyledMarkerContainer>
      <Collapse in={checked}>
        <MapPersonAssignBox
          onConfirmationStatusChange={onConfirmationStatusChange}
          jobId={jobId}
          usersRef={usersRef}
          userMenuData={userMenuData}
          onClickMenuItem={onClickMenuItem}
          markerRightClicked={true}
          setRightClickCheck={setRightClickCheck}
        />
      </Collapse>
    </>
  );
};

export default memo(MapMarkerPin);
