import React from "react";
import { styled, Collapse } from "@mui/material";
import Faces from "./Faces";
import MapPersonAssignBox from "./MapPersonAssignBox";

const StyledMapPersonContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  zIndex: "9999",
}));

const StyledMapPersonPinContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "inline-block",
}));

const StyledMapPersonPin = styled("div")(({ theme }) => ({
  width: 32,
  height: 32,
  position: "absolute",
  left: "50%",
  cursor: "pointer",
  marginLeft: -16,
  top: -44,
  borderRadius: "50%",
  background: theme.colors.white.primary,
  filter: "drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25))",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&::after": {
    content: '""',
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: `12px solid ${theme.colors.white.primary}`,
    position: "absolute",
    left: "50%",
    top: 30,
    marginLeft: -8,
    zIndex: -1,
  },
}));

const MapPersonPin = ({
  name,
  color,
  src,
  onConfirmationStatusChange,
  jobId,
  usersRef,
  userMenuData,  
  onClickMenuItem,
}) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const StyledMapPersonName = styled("div")(({ theme }) => ({
    background: color,
    fontSize: "12px",
    padding: "3px 7px",
    color: theme.colors.white.primary,
    borderRadius: "25px",
    fontWeight: 400,
  }));

  return (
    <StyledMapPersonContainer>
      <Collapse in={checked}>
        <MapPersonAssignBox
          onConfirmationStatusChange={onConfirmationStatusChange}
          jobId={jobId}
          usersRef={usersRef}
          userMenuData={userMenuData}
          onClickMenuItem={onClickMenuItem}
        />
      </Collapse>

      <StyledMapPersonPinContainer>
        <StyledMapPersonPin onClick={handleChange}>
          <Faces color={color} src={src} />
        </StyledMapPersonPin>
        <StyledMapPersonName>{name}</StyledMapPersonName>
      </StyledMapPersonPinContainer>
    </StyledMapPersonContainer>
  );
};

export default MapPersonPin;
