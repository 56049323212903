import { memo } from "react";
import PropTypes from "prop-types";
import { styled, InputBase, alpha, Box, useTheme } from "@mui/material";
import { mdiChevronDown, mdiClose, mdiMagnify } from "@mdi/js";
import MuiIconButton from "../MuiIconButton";
import { en } from "../../locale/en";
import MuiTooltip from "../MuiTooltip";
import Icon from "@mdi/react";
import MuiButton from "../MuiButton";

const StyledInput = styled(InputBase)(({ theme }) => ({
  color: theme.colors.white.primary,
  "& .MuiInputBase-input": {
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    height: "36px",
    padding: " 0 0 0 12px",
  },
  [theme.breakpoints.down("lg")]: {
    flexGrow: 1,
  },
  [theme.breakpoints.only("lg")]: {
    width: 600,
  },
  [theme.breakpoints.only("xl")]: {
    width: 650,
  },
  [theme.breakpoints.only("xxl")]: {
    width: 700,
  },
  [theme.breakpoints.only("xxxl")]: {
    width: 750,
  },
  [theme.breakpoints.up("xxxl")]: {
    width: 900,
  },
}));

const Search = ({ sx, onClose, handleOnClickThisPage }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        ...sx,
      }}
    >
      <MuiIconButton
        sx={{
          backgroundColor: alpha(theme.colors.white.primary, 0.15),
          borderRadius: "6px 0 0 6px",
          height: "36px",
          width: "36px",
          marginRight: "2px",
          ":hover": {
            backgroundColor: alpha(theme.colors.white.primary, 0.25),
          },
        }}
      >
        <MuiTooltip placement={"bottom"}>
          <Icon path={mdiMagnify} size={1} />
        </MuiTooltip>
      </MuiIconButton>

      <StyledInput
        placeholder={en.search}
        sx={{
          ":hover": {
            background: alpha(theme.colors.white.primary, 0.15),
          },
          input: {
            "&::placeholder": {
              opacity: 1,
              fontWeight: 400,
              fontSize: 16,
            },
          },
        }}
        inputProps={{ "aria-label": en.search }}
      />
      <MuiButton
        title={en.thisPage}
        endIcon={<Icon path={mdiChevronDown} size={1} />}
        sx={{
          borderRadius: "0px 6px 6px 0px",
          fontWeight: 400,
          backgroundColor: alpha(theme.colors.white.primary, 0.15),
          [theme.breakpoints.down("xs")]: {
            fontSize: 0,
          },
          ":hover": {
            background: alpha(theme.colors.white.primary, 0.25),
          },
        }}
        onClick={(event) => handleOnClickThisPage(event)}
      />

      <MuiIconButton
        onClick={onClose}
        sx={{ mr: 0, ml: 1, display: { lg: "none" } }}
      >
        <MuiTooltip title={en.close} placement={"bottom"}>
          <Icon path={mdiClose} size={1} />
        </MuiTooltip>
      </MuiIconButton>
    </Box>
  );
};

Search.propTypes = {
  sx: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default memo(Search);
