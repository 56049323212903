export const en = {
  miScheduling: "MiScheduling",
  messages: "Messages",
  notifications: "Notifications",
  settings: "Settings",
  more: "More",
  logo: "logo",
  switchModule: " Switch Module",
  search: "Search",
  thisPage: " This Page",
  close: "Close",
  showHide: "Show/Hide",
  placeholder: "placeholder",
  allTabs: "All Tabs",
  sendToPublishQueue: "Send to Publish Queue",
  filter: "Filter",
  print: "Print",
  inspectionAssignments: "Inspection Assignments",
};
