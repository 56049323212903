import { memo } from "react";
import { Tooltip, Grow } from "@mui/material";

const MapTooltip = ({ color, children, ...props }) => {
  return (
    <Tooltip
      componentsProps={{
        popper: {
          sx: {
            lineHeight: 1,
          },
        },
        tooltip: {
          sx: {
            bgcolor: color,
            fontSize: "12px",
            padding: "3px 7px",
            whiteSpace: "nowrap",
            fontWeight: 400,
            "& .MuiTooltip-arrow": {
              color: color,
            },
            "&.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
              marginTop: "6px",
              borderRadius: "25px",
            },
            "&.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop": {
              marginBottom: "8px",
              maxWidth: "135px",
              textAlign: "center",
            },
          },
        },
      }}
      TransitionComponent={Grow}
      disableInteractive
      PopperProps={{
        disablePortal: true,
        keepMounted: true,
        modifiers: [
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: "document",
            },
          },
          {
            name: "preventOverflow",
            enabled: false,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ],
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default memo(MapTooltip);
