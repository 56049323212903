import { memo, useCallback, useEffect, useRef, useState } from "react";
import { IconButton, Stack, useTheme, Button, styled } from "@mui/material";
import { Icon } from "@iconify/react";
import Faces from "./Faces";
import AssignmentMenu from "../../screens/Schedule/Inspection/AssignmentTab/AssignmentMenu";

const TASKDATA = [
  {
    id: 1,
    label: "Reassign Job",
    value: "Reassign",
    icon: "mdi:calendar-account",
    color: "#2E68FB",
  },
  {
    id: 2,
    label: "Approve Job",
    value: "Approved",
    icon: "mdi:thumb-up-outline",
    color: "#43A047",
  },
  {
    id: 3,
    label: "Reschedule Job",
    value: "Rescheduled",
    icon: "mdi:thumb-down-outline",
    color: "#F44336",
  },
  {
    id: 4,
    label: "Place on Hold",
    value: "Hold",
    icon: "mdi:hand-front-right",
    color: "#8E24AA",
  },
];

const StyledAssignBox = styled("div")(({ theme, markerRightClicked }) => ({
  position: "absolute",
  display: "inline-block",
  width: 140,
  boxShadow: "0px 4.61734px 6.15645px rgba(0, 0, 0, 0.15)",
  bottom: markerRightClicked ? "-90px" : "70px",
  right: markerRightClicked ? "-80px" : "50%",
  background: theme.colors.white.primary,
  borderRadius: "8px",
  marginRight: -57,
}));

const StyledAssignBoxHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: theme.colors.blue.blueMedium,
  padding: "0px 5px 0px 2px",
  borderRadius: "8px 8px 0px 0px",
  "& .MuiAvatar-root": {
    transform: "scale(0.75)",
  },
}));

const StyledAssignBoxContent = styled("div")(({ theme }) => ({
  display: "inline-block",
  padding: "5px 0",
  width: "100%",
}));

const IconButtonCustom = ({ icon, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        padding: 0,
        fontSize: 16,
        color: theme.colors.white.primary,
      }}
      onClick={onClick}
    >
      <Icon icon={icon} />
    </IconButton>
  );
};

const ButtonCustom = ({ icon, name, iconColor = "inherit", onClick }) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        padding: "0px 12px",
        fontWeight: " 400",
        fontSize: "12px",
        textTransform: "capitalize",
        color: theme.colors.blueGray.primary,
        width: "100%",
        height: "30px",
        justifyContent: "flex-start",
        borderRadius: 0,
        "& .MuiButton-startIcon": {
          margin: 0,
          marginRight: "8px",
          "& svg": {
            fontSize: "16px",
            color: iconColor,
          },
        },
        "&:hover": {
          backgroundColor: theme.colors.gray.light,
        },
      }}
      variant="text"
      startIcon={<Icon icon={icon} />}
      onClick={onClick}
    >
      {name}
    </Button>
  );
};

const MapPersonAssignBox = ({
  color,
  onConfirmationStatusChange,
  jobId,
  usersRef,
  userMenuData,
  onClickMenuItem,
  markerRightClicked = false,
  setRightClickCheck,
}) => {
  const [AnchorEl, setAnchorEl] = useState(null);

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener("click", handleClick, true);
      return () => {
        document.removeEventListener("click", handleClick, true);
      };
    }, [ref, callback]);
    return ref;
  };

  const ref = useOutsideClick(() => {
    if (!markerRightClicked) {
      return;
    }
    setRightClickCheck(false);
  });

  const onSetAnchor = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      setRightClickCheck(true);
    },
    [setRightClickCheck]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onUserChange = (userID) => {
    if (userID !== undefined) {
      onClickMenuItem(jobId, userID);
      setAnchorEl(null);
    }
  };

  return (
    <>
      <StyledAssignBox markerRightClicked={markerRightClicked}>
        {!markerRightClicked && (
          <StyledAssignBoxHeader>
            <Faces color={color} src="/static/images/avatar/1.jpg" />
            <Stack direction="row" spacing={1}>
              <IconButtonCustom icon="mdi:message-text-outline" />
              <IconButtonCustom icon="material-symbols:phone-in-talk-outline" />
              <IconButtonCustom icon="mdi:email-outline" />
            </Stack>
          </StyledAssignBoxHeader>
        )}
        <StyledAssignBoxContent ref={ref}>
          <Stack direction="column" spacing={0}>
            {TASKDATA?.map((item, i) => (
              <ButtonCustom
                key={item?.id}
                name={item.label}
                icon={item.icon}
                iconColor={item.color}
                onClick={(e) => {
                  if (item?.id !== 1) {
                    onConfirmationStatusChange(
                      Array.isArray(jobId) ? jobId : [jobId],
                      item?.value
                    );
                  } else {
                    onSetAnchor(e);
                  }
                }}
              />
            ))}
          </Stack>
        </StyledAssignBoxContent>
      </StyledAssignBox>

      <AssignmentMenu
        usersRef={usersRef}
        userMenuData={userMenuData}
        handleClose={handleClose}
        onClickMenuItem={onUserChange}
        anchorEl={AnchorEl}
        open={Boolean(AnchorEl)}
      />
    </>
  );
};

export default memo(MapPersonAssignBox);
