import { memo } from "react";
import { Checkbox, useTheme, Badge } from "@mui/material";
import MuiTooltip from "../MuiTooltip";
import { Icon } from "@iconify/react";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const MapLayersButtonCheckbox = ({
  icon,
  checkedIcon,
  title,
  badgeContent,
  ...props
}) => {
  const theme = useTheme();
  return (
    <MuiTooltip title={title} placement="left">
      <Badge
        badgeContent={badgeContent}
        sx={{
          "& .MuiBadge-badge": {
            bgcolor: theme.colors.blue.blueMedium,
            color: theme.colors.white.primary,
            border: `1px solid ${theme.colors.white.primary}`,
          },
        }}
      >
        <Checkbox
          sx={{
            width: 30,
            height: 30,
            p: 0,
            borderRadius: "4px",
            boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.03)",
            bgcolor: theme.colors.white.primary,
            border: `1px solid ${theme.colors.white.primary}`,
            color: theme.colors.blueGray.mediumDark,
            fontSize: 20,
            minWidth: "inherit",
            "&:hover": {
              bgcolor: theme.colors.white.primary,
              borderColor: theme.colors.blue.border,
              boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.03)",
            },
            "& svg": {
              width: 21,
              height: 21
            },
            "&.Mui-checked": {
              bgcolor: theme.colors.blue.blueMedium,
              color: theme.colors.white.primary,
              borderColor: theme.colors.blue.blueMedium,
              "&:hover": {
                borderColor: theme.colors.blue.blueMedium,
                boxShadow: `0px 0px 0px 1.5px ${theme.colors.blue.border}`,
              },
            },
          }}
          {...label}
          icon={<Icon icon={icon} />}
          checkedIcon={<Icon icon={checkedIcon == null ? icon : checkedIcon} />}

          {...props}
        />
      </Badge>
    </MuiTooltip>
  );
};

export default memo(MapLayersButtonCheckbox);
