import { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import {
  Tabs,
  Tab,
  Box,
  styled,
  useTheme,
  Typography,
  alpha,
} from "@mui/material";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { en } from "../../locale/en";
import LogoGrab from "../../assets/LogoGrab.svg";
import MuiDivider from "../MuiDivider";
import MuiIconButton from "../MuiIconButton";
import MuiTooltip from "../MuiTooltip";
import NavScrollButton from "./NavScrollButton";
import MuiButton from "../MuiButton";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  alignItems: "center",
  minHeight: 0,
  padding: 3,
  color: theme.colors.white.primary,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: theme.colors.white.primary,
  borderRadius: 8,
  fontWeight: 400,
  minHeight: 0,
  minWidth: 58,
  fontSize: 14,
  lineHeight: "18px",
  padding: 8,
  marginRight: theme.spacing(3),
  ":hover": {
    backgroundColor: theme.colors.blue.secondary,
  },
  "&.Mui-selected": {
    fontWeight: 600,
    backgroundColor: theme.colors.white.primary,
    color: theme.colors.blue.primary,
    letterSpacing: "0em",
  },
}));

const NavButtons = ({ items, collapsed, onCollapsed }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const RightContainer = () => (
    <Box
      sx={{
        display: { xxxs: "none", lg: "flex" },
        alignItems: "center",
        mr: 2,
      }}
    >
      <MuiIconButton
        onClick={onCollapsed}
        sx={{
          ":hover": {
            background: theme.colors.blue.secondary,
          },
        }}
      >
        <MuiTooltip title={en.showHide} placement={"left"}>
          <Icon path={collapsed ? mdiChevronUp : mdiChevronDown} size={1} />
        </MuiTooltip>
      </MuiIconButton>
    </Box>
  );

  const LeftContainer = () => (
    <Box
      sx={{
        display: { xxxs: "none", lg: "flex", alignItems: "center" },
        pl: 2,
      }}
    >
      <Box sx={{ mr: 1 }}>
        <img src={LogoGrab} alt={en.logo} />
      </Box>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 14,
        }}
      >
        {en.miScheduling}
      </Typography>
      <MuiDivider
        height={25}
        sx={{
          ml: 2,
        }}
      />
      <MuiButton
        title={en.switchModule}
        startIcon={<Icon path={mdiChevronDown} size={1} />}
        sx={{
          fontSize: 12,
          fontWeight: 700,
          borderRadius: "8px",
          width: 137,
          ml: 2,
          display: { xxxs: "none", lg: "flex" },
          ":hover": {
            background: alpha(theme.colors.white.primary, 0.15),
          },
        }}
      />
    </Box>
  );

  const getTabItems = () => {
    return items.map((item) => {
      return (
        <StyledTab
          key={item.path}
          label={item.label}
          value={item.path}
          disableFocusRipple
          disableRipple
          component={Link}
          to={item.path}
        />
      );
    });
  };

  const matchPath = (items = []) => {
    const navPath = pathname.split("/")[1] || "";
    const matchItem = items.find(({ path }) => {
      return path === navPath;
    });
    return matchItem?.path || "dashboard";
  };

  const selected = matchPath(items);

  return (
    <Box
      sx={{
        bgcolor: theme.colors.blue.primary,
        color: theme.colors.white.primary,
        display: "flex",
      }}
    >
      {!collapsed && <LeftContainer />}
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <StyledTabs
          value={selected}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{ style: { display: "none" } }}
          ScrollButtonComponent={NavScrollButton}
          aria-label="navigation tabs"
        >
          {getTabItems()}
        </StyledTabs>
      </Box>
      <RightContainer />
    </Box>
  );
};

NavButtons.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onCollapsed: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(NavButtons);
