import React, { memo } from "react";
import PropTypes from "prop-types";
import { alpha, Tooltip, useTheme } from "@mui/material";

const MuiTooltip = ({ placement, title, children, sx }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            background: alpha(theme.colors.black.primary, 0.8),
            fontWeight: 400,
            fontSize: 14,
            borderRadius: 10,
            ...sx,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

MuiTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.element.isRequired,
  sx: PropTypes.object,
};

MuiTooltip.defaultProps = {
  sx: {},
};
export default memo(MuiTooltip);
