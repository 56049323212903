import { lazy } from "react";
import { Navigate } from "react-router-dom";

import { MainLayout } from "../layouts";
import ScheduleRoute from "./ScheduleRoutes";

const Dashboard = lazy(() => import("../screens/Dashboard/Dashboard"));
const Schedule = lazy(() => import("../screens/Schedule/Schedule"));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Navigate to="dashboard" replace />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "schedule",
      element: <Schedule />,
      children: ScheduleRoute,
    },
  ],
};

export default MainRoutes;
