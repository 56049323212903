import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { mdiCheckBold } from "@mdi/js";
import Icon from "@mdi/react";
import { Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const BottomMenu = ({
  open,
  handleClose,
  anchorEl,
  tabItems,
  value,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  const getBottomItems = () => {
    return tabItems.map((item, index) => {
      return (
        <MenuItem
          sx={{
            ":hover": {
              backgroundColor: theme.colors.gray.light,
            },
            padding: theme.spacing(0, 5, 0, 2),
            minHeight: 35,
          }}
          key={index}
          value={item.path}
          selected={item.path === value}
          component={Link}
          to={item.path}
        >
          {item.path === value ? (
            <Icon
              path={mdiCheckBold}
              size={0.6}
              color={theme.colors.blue.bluePrimary}
            />
          ) : (
            <Icon size={0.6} path={""} />
          )}

          <Typography
            sx={{
              fontSize: 12,
              fontWeight: index === value ? 500 : 400,
              color:
                item.path === value
                  ? theme.colors.blue.bluePrimary
                  : theme.colors.blueGray.primary,
              ml: 1.5,
            }}
          >
            {item.label}
          </Typography>
        </MenuItem>
      );
    });
  };
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{
        ...sx,
        "& .MuiMenuItem-root.Mui-selected": {
          background: "white",
        },
        top: "-25px",
      }}
      {...rest}
    >
      {getBottomItems()}
    </Menu>
  );
};
BottomMenu.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  sx: PropTypes.object,
};

BottomMenu.defaultProps = {
  sx: {},
};
export default React.memo(BottomMenu);
