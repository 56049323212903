export const mapToJobsAndUser = (list) => {
  const jobs = new Map();
  const users = new Map();
  const cd = new Map();
  const td = [];

  if (Array.isArray(list)) {
    list.forEach((d) => {
      const { User, ...rest } = d || {};
      const { UserId, JobId } = rest || {};
      jobs.set(JobId, rest);
      users.set(UserId, { ...User, UserId });
      td.push({ JobId: d.JobId, UserId });
      if (cd.has(UserId)) {
        const j = cd.get(UserId);
        j.push(JobId);
      } else {
        cd.set(UserId, [JobId]);
      }
    });
  }
  return { jobs, users, td, cd };
};

export const filterByJobTypeName = (name, jobs) => {
  const td = [];
  const cd = new Map();
  jobs.forEach((value) => {
    const { JobId, UserId, JobTypeName } = value;
    let isAdd = name === "All" ? true : name === JobTypeName ? true : false;
    if (isAdd) {
      td.push({ JobId, UserId });
      if (cd.has(UserId)) {
        const j = cd.get(UserId);
        j.push(JobId);
      } else {
        cd.set(UserId, [JobId]);
      }
    }
  });
  return { td, cd };
};

export const getJobsData = (jobsRef, JobId) => jobsRef.current.get(JobId) || {};

export const getUsersData = (usersRef, UserId) =>
  usersRef.current.get(UserId) || {};

export const setJobsData = (jobsRef, JobId, jd) =>
  jobsRef.current.set(JobId, jd);

export const setUsersData = (usersRef, UserId, ud) =>
  usersRef.current.set(UserId, ud);

export const updateJobsData = (jobsRef, JobIds, d) => {
  JobIds.forEach((JobId) => {
    const jd = getJobsData(jobsRef, JobId);
    setJobsData(jobsRef, JobId, { ...jd, ...d });
  });
};

export const updateTableData = (td, JobId, UserId) => {
  return td.map((d) => (d.JobId === JobId ? { ...d, UserId } : d));
};

export const updateCalendarData = (
  calendarData,
  prevUserId,
  JobId,
  UserId,
  index = -1
) => {
  const cd = new Map();
  calendarData.forEach((v, key) => {
    if (prevUserId === UserId) {
      cd.set(key, v);
      return;
    }
    if (prevUserId === key) {
      cd.set(
        key,
        v.filter((id) => id !== JobId)
      );
    } else if (UserId === key) {
      if (index !== -1) {
        v.splice(index, 0, JobId);
        cd.set(key, [...v]);
      } else {
        cd.set(key, [...v, JobId]);
      }
    } else {
      cd.set(key, v);
    }
  });
  return cd;
};

export const getMaxSizeFromMap = (mapObj) => {
  let size = 0;

  mapObj.forEach((value) => {
    size = value.length > size ? value.length : size;
  });
  return size;
};

export const isValidEmail = (email) => {
  const emailExp =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  return email?.match(emailExp);
};

export const isPhoneNumber = (number) => {
  const numberExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return number?.match(numberExp);
};
