import { IconButton, useTheme, styled } from "@mui/material";
import { Icon } from "@iconify/react";

const StyledZoomDiv = styled("div")(({ theme }) => ({
  position: "relative",
  width: 30,
  height: 60,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: theme.colors.white.primary,
  boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.03)",
  border: `1px solid ${theme.colors.white.primary}`,
  borderRadius: 5,
  "&:hover": {
    borderColor: theme.colors.blue.border,
  },
}));

const StyledDivider = styled("div")(({ theme }) => ({
  width: "18px",
  height: "0.59px",
  background: theme.colors.blueGray.light,
  display: "inline-block",
  margin: "4px 0",
}));

const MapZoomButton = ({zoomInClick, zoomOutClick}) => {
  const theme = useTheme();

  const IconButtonStyles = {
    padding: 0,
    height: "24px",
    width: "24px",
    color: theme.colors.blueGray.mediumDark,
    "&:hover":{
      background: theme.colors.white.primary
    }
  };

  return (
    <StyledZoomDiv>
      <IconButton sx={IconButtonStyles} onClick={zoomInClick}>
        <Icon icon="mdi:plus" />
      </IconButton>
      <StyledDivider />
      <IconButton sx={IconButtonStyles} onClick={zoomOutClick}>
        <Icon icon="mdi:minus" />
      </IconButton>
    </StyledZoomDiv>
  );
};

export default MapZoomButton;
