import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { mdiCogOutline, mdiCropSquare } from "@mdi/js";
import Icon from "@mdi/react";
import MuiTooltip from "../MuiTooltip";
import MuiIconButton from "../MuiIconButton";
import { en } from "../../locale/en";

const MenuIcons = ({ open, handleClose, anchorEl, sx, ...rest }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{ ...sx }}
      {...rest}
    >
      <MenuItem onClick={handleClose}>
        <MuiIconButton>
          <MuiTooltip title={en.placeholder} placement={"right"}>
            <Icon path={mdiCropSquare} size={1} />
          </MuiTooltip>
        </MuiIconButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <MuiIconButton>
          <MuiTooltip title={en.placeholder} placement={"right"}>
            <Icon path={mdiCropSquare} size={1} />
          </MuiTooltip>
        </MuiIconButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <MuiIconButton>
          <MuiTooltip title={en.placeholder} placement={"right"}>
            <Icon path={mdiCogOutline} size={1} />
          </MuiTooltip>
        </MuiIconButton>
      </MenuItem>
    </Menu>
  );
};
MenuIcons.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  sx: PropTypes.object,
};

MenuIcons.defaultProps = {
  sx: {},
};
export default React.memo(MenuIcons);
