import { lazy } from "react";
import { Navigate } from "react-router-dom";

const NewConstruction = lazy(() =>
  import("../screens/Schedule/NewConstruction/NewConstruction")
);
const Inspection = lazy(() =>
  import("../screens/Schedule/Inspection/Inspection")
);
const Dispatch = lazy(() => import("../screens/Schedule/Dispatch/Dispatch"));
const ScheduleTab = lazy(() =>
  import("../screens/Schedule/Inspection/ScheduleTab")
);
const AssignmentTab = lazy(() =>
  import("../screens/Schedule/Inspection/AssignmentTab/AssignmentTab")
);
const AssignmentTabV2 = lazy(() =>
  import("../screens/Schedule/Inspection/AssignmentTabV2")
);
const InspectionResultsTab = lazy(() =>
  import("../screens/Schedule/Inspection/InspectionResultsTab")
);
const HistoryTab = lazy(() =>
  import("../screens/Schedule/Inspection/HistoryTab")
);
//--dispatch
const ScheduleDispatchTab = lazy(() =>
  import("../screens/Schedule/Dispatch/ScheduleDispatchTab/ScheduleDispatch")
);

const UnScheduleDispatchTab = lazy(() =>
  import("../screens/Schedule/Dispatch/UnScheduleDispatchTab")
);

const ApprovalsDispatchTab = lazy(() =>
  import("../screens/Schedule/Dispatch/ApprovalsDispatchTab")
);
const PublishingQueueDispatchTab = lazy(() =>
  import("../screens/Schedule/Dispatch/PublishingQueueDispatchTab")
);
const HistoryDispatchTab = lazy(() =>
  import("../screens/Schedule/Dispatch/HistoryDispatchTab")
);

const ScheduleRoute = [
  {
    path: "",
    element: <Navigate to="new-construction" replace />,
  },
  {
    path: "new-construction",
    element: <NewConstruction />,
  },
  {
    path: "inspection",
    element: <Inspection />,
    children: [
      {
        path: "",
        element: <Navigate to="schedule" replace />,
      },
      {
        path: "schedule",
        element: <ScheduleTab />,
      },
      {
        path: "assignment",
        element: <AssignmentTab />,
      },
      {
        path: "assignment-v2",
        element: <AssignmentTabV2 />,
      },
      {
        path: "inspection-results",
        element: <InspectionResultsTab />,
      },
      {
        path: "history",
        element: <HistoryTab />,
      },
    ],
  },
  {
    path: "dispatch",
    element: <Dispatch />,
    children: [
      {
        path: "",
        element: <Navigate to="schedule" replace />,
      },
      {
        path: "schedule",
        element: <ScheduleDispatchTab />,
      },
      {
        path: "unscheduled",
        element: <UnScheduleDispatchTab />,
      },
      {
        path: "approvals",
        element: <ApprovalsDispatchTab />,
      },
      {
        path: "publishing-queue",
        element: <PublishingQueueDispatchTab />,
      },
      {
        path: "history",
        element: <HistoryDispatchTab />,
      },
    ],
  },
];

export default ScheduleRoute;
