import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import MuiIconButton from "../MuiIconButton";

const NavScrollButton = (props) => {
  const directionIcons = {
    left: mdiChevronLeft,
    right: mdiChevronRight,
  };
  const visibility = props.disabled ? "hidden" : "visible";
  // const display = props.disabled ? "none" : "flex";
  //   if (props.disabled) {
  //     return null;
  //   }
  return (
    <MuiIconButton
      sx={{
        ":hover": { background: "none" },
        visibility,
        background:
          "linear-gradient(270deg, #0D47A1 50%, rgba(13, 71, 161, 0) 100%)",
      }}
      onClick={props.onClick}
    >
      <Icon path={directionIcons[props.direction]} size={1} />
    </MuiIconButton>
  );
};

export default NavScrollButton;
