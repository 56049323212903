import React, { memo, useEffect, useState } from "react";
import { Box, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const StyledBox = styled(Box)(({ theme }) => ({
  minWidth: 115,
  minHeight: 35,
  display: "flex",
  border: "1px solid",
  borderRadius: "4px",
  ":hover": {
    border: "1px solid",
    borderColor: theme.colors.blue.border,
    outline: "1.5px solid",
    outlineColor: theme.colors.blue.border,
  },
  background: theme.colors.white.primary,
  cursor: "pointer",
  overflow: "hidden",
}));

const StyledCountTypography = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 400,
  color: theme.colors.blueGray.primary,
  borderRadius: "4px",
  padding: "0px 4px",
}));

const UserCard = ({
  jobId,
  label,
  color,
  count = null,
  countBackground,
  onClickCard,
  activeBorderColor,
  selected = false,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const [isSelected, setIsSelected] = useState(false);

  const onClick = (event) => {
    event.stopPropagation();
    if (onClickCard) {
      onClickCard(event, jobId);
      return;
    }
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <StyledBox
      sx={{
        borderColor: isSelected
          ? activeBorderColor || theme.colors.blue.blueMedium
          : theme.colors.gray.border,
        outline: isSelected ? "1px solid" : "none",
        outlineColor: isSelected
          ? activeBorderColor || theme.colors.blue.blueMedium
          : "none",
        ...sx,
      }}
      onClick={onClick}
      {...rest}
    >
      <Box
        sx={{
          width: 7,
          borderRadius: "3px 0 0 3px",
          backgroundColor: color,
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
          overflow: "hidden",
          padding: "0px 5px",
        }}
      >
        <Typography
          sx={{
            fontSize: 11,
            fontWeight: 500,
            color: theme.colors.blueGray.primary,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </Typography>
        {count !== null ? (
          <StyledCountTypography
            sx={{
              background: countBackground || theme.colors.blueGray.pureLight,
            }}
          >
            {count}
          </StyledCountTypography>
        ) : null}
      </Box>
    </StyledBox>
  );
};

UserCard.propTypes = {
  jobId: PropTypes.string,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  count: PropTypes.number,
  minHeight: PropTypes.string,
  width: PropTypes.string,
  onClickCard: PropTypes.func,
  activeBorderColor: PropTypes.func,
  sx: PropTypes.object,
  selected: PropTypes.bool,
};

export default memo(UserCard);
