import { Avatar, styled } from "@mui/material";

const AvatarStyled = styled(Avatar)(({ theme, ...props }) => ({
  width: 27,
  height: 27,
  borderRadius: "50%",
  fontSize: 14,
  boxShadow: `0px 0px 0px 1.5px ${props.color}`,
}));

const Faces = ({ color, src }) => {
  return <AvatarStyled color={color} src={src} />;
};

export default Faces;
