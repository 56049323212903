import { memo } from "react";
import { styled, Grow, Tooltip, useTheme } from "@mui/material";

const MapMarkerInfoBox = ({
  children,
  infoName,
  infoAddress,
  infoCity,
  infoDesc,
  infoImage,
  ...props
}) => {
  const theme = useTheme();
  const StyledBox = styled("div")(({ theme }) => ({
    width: 175,
    position: "relative",
    borderRadius: "6px",
    background: theme.colors.white.primary,
    display: "flex",
  }));

  const StyledBoxImg = styled("div")(({ theme }) => ({
    minWidth: 42,
    position: "relative",
    borderRadius: "6px",
    "& img": {
      width: 42,
      minHeight: "100%",
      borderRadius: 3,
      filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.03))",
      objectFit: "cover",
    },
  }));

  const StyledBoxContent = styled("div")(({ theme }) => ({
    width: "calc(100% - 42px)",
    paddingLeft: 10,
    "& p": {
      fontSize: 11,
      color: theme.colors.blueGray.medium,
      margin: "6px 0px",
      fontWeight: 400,
    },
    "& .title": {
      fontWeight: 500,
      textTransform: "capitalize",
      color: theme.colors.blueGray.primary,
    },
  }));

  const MapMarkerInfoBoxContent = () => {
    return (
      <StyledBox>
        <StyledBoxImg>
          <img src={infoImage} alt="1" />
        </StyledBoxImg>
        <StyledBoxContent>
          <p className="title">{infoName}</p>
          <p className="title">{infoAddress}</p>
          <p>{infoCity}</p>
          <p>{infoDesc}</p>
        </StyledBoxContent>
      </StyledBox>
    );
  };

  return (
    <Tooltip
      title={<MapMarkerInfoBoxContent />}
      placement="top"
      arrow
      componentsProps={{
        popper: {
          sx: {
            lineHeight: 1,
          },
        },
        tooltip: {
          sx: {
            bgcolor: theme.colors.white.primary,
            fontSize: "10px",
            padding: "5px",
            boxShadow: "0px 4.61734px 6.15645px rgba(0, 0, 0, 0.15)",
            "& .MuiTooltip-arrow": {
              color: theme.colors.white.primary,
            },
            "&.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop": {
              marginBottom: "8px",
            },
          },
        },
      }}
      TransitionComponent={Grow}
      disableInteractive
      PopperProps={{
        disablePortal: true,
        keepMounted: true,
        modifiers: [
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: "document",
            },
          },
          {
            name: "preventOverflow",
            enabled: false,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ],
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default memo(MapMarkerInfoBox);
