import { memo } from "react";
import PropTypes from "prop-types";
import { Badge, styled } from "@mui/material";
import Icon from "@mdi/react";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    fontSize: 12,
    fontWeight: 400,
    color: theme.colors.white.primary,
    minWidth: 18,
    height: 18,
    padding: 0,
    background: theme.colors.red.primary,
  },
}));
const MuiBadge = ({ path, content, sx, size, children }) => {
  return (
    <StyledBadge badgeContent={content} sx={sx}>
      {children || <Icon size={size} path={path} />}
    </StyledBadge>
  );
};
MuiBadge.propTypes = {
  path: PropTypes.string,
  content: PropTypes.number,
  sx: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
MuiBadge.defaultProps = { sx: {}, size: "22px" };
export default memo(MuiBadge);
