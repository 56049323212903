export const jobConfirmationStatus = {
  NOT_SCHEDULED: "Not Scheduled",
  WAITING_TO_SCHEDULE: "Waiting To Scheduled",
  SCHEDULED: "Scheduled",
  HOLD: "Hold",
  APPROVED: "Approved",
  RESCHEDULED: "Rescheduled",
  COMPLETED: "Completed",
};

export const calendarItemHeight = 45;
export const calendarMargin = 4;
export const CalendarContainerWidth = 120;
export const Empty_timeline_Height = 42;
export const minTimeForTimeline = 8;
export const DispatchTimelineHeight = 69;
export const WorkOrderDrawerWidth = 475;
