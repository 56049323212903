import { styled } from "@mui/material";

const MapMarkerInfoWindow = ({
  name,
  address,
  city,
  desc,
  image,
  ...props
}) => {
  const StyledBoxOuter = styled("div")(({ theme }) => ({
    borderRadius: "6px",
    background: theme.colors.white.primary,
    boxShadow: "0px 4.61734px 6.15645px rgba(0, 0, 0, 0.15)",
    position: "absolute",
    bottom: "50px",
    transform: "translate(-43%, 0%)",
    padding: 5,
    display: "none",
    "&:after": {
      content: '""',
      width: "1em",
      height: "0.71em",
      background: theme.colors.white.primary,
      transform: "rotate(45deg)",
      display: "inline-block",
      position: "absolute",
      left: "50%",
      bottom: -3,
    },
  }));

  const StyledBox = styled("div")(({ theme }) => ({
    display: "flex",
    flexGrow: 1,
  }));

  const StyledBoxImg = styled("div")(({ theme }) => ({
    minWidth: 42,
    position: "relative",
    borderRadius: "6px",
    "& img": {
      width: 42,
      minHeight: "100%",
      borderRadius: 3,
      filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.03))",
      objectFit: "cover",
    },
  }));

  const StyledBoxContent = styled("div")(({ theme }) => ({
    width: "calc(100% - 42px)",
    paddingLeft: 10,
    paddingRight: 4,
    "& p": {
      fontSize: 11,
      color: theme.colors.blueGray.medium,
      margin: "4px 0px",
      fontWeight: 400,
      minWidth: "max-content",
    },
    "& .title": {
      fontWeight: 500,
      textTransform: "capitalize",
      color: theme.colors.blueGray.primary,
    },
  }));

  return (
    <StyledBoxOuter {...props}>
      <StyledBox>
        <StyledBoxImg>
          <img src={image} alt="1" />
        </StyledBoxImg>
        <StyledBoxContent>
          <p className="title">{name} Inspection</p>
          <p className="title">{address}</p>
          <p>{city}</p>
          <p>{desc}</p>
        </StyledBoxContent>
      </StyledBox>
    </StyledBoxOuter>
  );
};

export default MapMarkerInfoWindow;
