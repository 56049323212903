import { memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";

const MuiDivider = styled("div")(({ sx, height, theme }) => ({
  ...sx,
  width: 1,
  height: height,
  backgroundColor: theme.colors.blue.lightBlue,
}));

MuiDivider.propTypes = {
  height: PropTypes.number,
  sx: PropTypes.object,
};

export default memo(MuiDivider);
