import React, { memo } from "react";
import { Box, styled, useTheme } from "@mui/material";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";

const MUI_DATAGRID_KEY =
  "2a1203426ca4412164fd0b503afd0b83T1JERVI6MzkyOTgsRVhQSVJZPTE2NzgzODczMjgwMDAsS0VZVkVSU0lPTj0x";

LicenseInfo.setLicenseKey(MUI_DATAGRID_KEY);

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.blueGray.primary,
  border: "none",
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    justifyContent: "flex-start",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-pinnedColumns": {  
    boxShadow: "none", 
  },
}));

const MuiTable = ({ columns, rows, children, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        borderBottom: 1,
        borderColor: theme.colors.gray.border,
        mt: 1,
        position: "relative",
      }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        showColumnRightBorder
        rowHeight={30}
        headerHeight={30}
        {...rest}
      />
      {children}
    </Box>
  );
};

export default memo(MuiTable);
