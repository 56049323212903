import React, { memo } from "react";
import { Menu, MenuItem, useTheme } from "@mui/material";
import UserCard from "../../../../components/UserCard";
import { getUsersData } from "../../helper";

const AssignmentMenu = ({
  usersRef,
  userMenuData,
  handleClose,
  onClickMenuItem,
  anchorEl,
  open,
}) => {
  const theme = useTheme();
  const getAssignmentItems = () => {
    const items = [];

    userMenuData?.size &&
      userMenuData.forEach((value, key) => {
        const ud = getUsersData(usersRef, key);
        items.push(
          <MenuItem sx={{ height: 25, padding: 0.5 }} key={key}>
            <UserCard
              sx={{
                minWidth: 152,
                border: "none",
                ":hover": {
                  border: "none",
                  outline: "none",
                },
                minHeight: 23,
                backgroundColor: "transparent",
              }}
              color={ud.Color}
              label={`${ud.FirstName} ${ud.LastName}`}
              count={value.length}
              countBackground={theme.colors.white.primary}
              onClickCard={() => {
                onClickMenuItem(key);
              }}
            />
          </MenuItem>
        );
      });
    return items;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {getAssignmentItems()}
    </Menu>
  );
};

export default memo(AssignmentMenu);
