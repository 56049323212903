import React, { memo } from "react";
import { styled, alpha } from "@mui/material";
import MapTooltip from "./MapTooltip";
import MapMarkerInfoBox from "./MapMarkerInfoBox";
import MapMarkerSvg from "./MapMarkerSvg";

const MapMarker = ({
  name,
  color,
  address,
  type,
  count = "#",
  infoImage,
  infoName,
  infoAddress,
  infoDesc,
  infoCity,
  infoType = "S",
  selected = false,
}) => {
  const StyledMapMarkerContainerTooltip = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "50%",
    display: "inline-block",
  }));

  const StyledMapMarkerContainer = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    cursor: "pointer",
    width: "42px",
    height: "42px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      boxShadow: `0px 0px 0px 1px ${color}`,
      background: theme.colors.white.primary,
    },
    "&.selected": {
      boxShadow: `0px 0px 0px 1px ${color}`,
      background: theme.colors.white.primary,
    },
    "& svg": {
      position: "relative",
      zIndex: 1,
    },
  }));

  const StyledMapMarkerRound = styled("div")(({ theme }) => ({
    margin: 0,
    width: 18,
    height: 18,
    fontSize: 11,
    textAlign: "center",
    lineHeight: "18px",
    fontWeight: 600,
    position: "absolute",
    zIndex: 1,
    color: color,
    top: 7,
  }));

  const MapMarkerPin = () => {
    return (
      <StyledMapMarkerContainerTooltip>
        <MapMarkerInfoBox
          infoName={infoName}
          infoImage={infoImage}
          infoAddress={infoAddress}
          infoCity={infoCity}
          infoDesc={infoDesc}
        >
          <StyledMapMarkerContainerTooltip>
            <MapTooltip color={color} title={name} placement="bottom">
              <StyledMapMarkerContainerTooltip>
                <StyledMapMarkerContainer
                  className={selected ? "selected" : null}
                >
                  <MapMarkerSvg color={color} />
                  <StyledMapMarkerRound>{count}</StyledMapMarkerRound>
                </StyledMapMarkerContainer>
              </StyledMapMarkerContainerTooltip>
            </MapTooltip>
          </StyledMapMarkerContainerTooltip>
        </MapMarkerInfoBox>
      </StyledMapMarkerContainerTooltip>
    );
  };

  const StyledMapMarkerDotContainer = styled("div")(({ theme }) => ({
    width: "15px",
    height: "15px",
    position: "relative",
    borderRadius: "50%",
  }));

  const StyledMapMarkerDotCircle = styled("div")(({ theme }) => ({
    width: "16px",
    height: "16px",
    position: "relative",
    borderRadius: "50%",
    background: color,
    boxShadow: `0px 0px 0px 3px ${alpha(color, 0.15)}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 10,
    cursor: "pointer",
    textTransform: "uppercase",
    color: theme.colors.white.primary,
    border: `0.5px solid ${color}`,
  }));

  const MapMarkerDot = () => {
    return (
      <MapMarkerInfoBox
        infoName={infoName}
        infoImage={infoImage}
        infoAddress={infoAddress}
        infoCity={infoCity}
        infoDesc={infoDesc}
      >
        <StyledMapMarkerDotContainer>
          <MapTooltip color={color} title={name} placement="bottom">
            <StyledMapMarkerDotContainer>
              <StyledMapMarkerDotCircle>{infoType}</StyledMapMarkerDotCircle>
            </StyledMapMarkerDotContainer>
          </MapTooltip>
        </StyledMapMarkerDotContainer>
      </MapMarkerInfoBox>
    );
  };

  return (
    <>
      {type === "pin" && <MapMarkerPin />}
      {type === "dot" && <MapMarkerDot />}
    </>
  );
};

export default memo(MapMarker);
