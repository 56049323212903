import * as React from "react";
import PropTypes from "prop-types";
import { Popover, styled } from "@mui/material";

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    width: "264px",
    height: "280px",
    background: theme.colors.white.primary,
    boxShadow: " 0px 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: " 10px",
  },
}));

const MuiPopover = ({
  open,
  handlePopoverClose,
  anchorEl,
  children,
  ...rest
}) => {
  return (
    <StyledPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handlePopoverClose}
      {...rest}
    >
      {children}
    </StyledPopover>
  );
};
MuiPopover.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.any,
  handlePopoverClose: PropTypes.func,
};

MuiPopover.defaultProps = {};
export default React.memo(MuiPopover);
