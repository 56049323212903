import { useState, useCallback, memo } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Avatar,
  useTheme,
  alpha,
  Collapse,
  Grid,
} from "@mui/material";
import {
  mdiChevronDown,
  mdiCommentTextOutline,
  mdiBellOutline,
  mdiMagnify,
  mdiCogOutline,
  mdiPlusBoxOutline,
} from "@mdi/js";
import logoSmall from "../../assets/logo-small.svg";
import MuiDivider from "../MuiDivider";
import Search from "./Search";
import MuiIconButton from "../MuiIconButton";
import { en } from "../../locale/en";
import MuiTooltip from "../MuiTooltip";
import MuiBadge from "../MuiBadge";
import NavButtons from "./NavButtons";
import Icon from "@mdi/react";
import MuiPopover from "../MuiPopover";
import MuiButton from "../MuiButton";
import MenuIcons from "./MenuIcons";

const tabItems = [
  { label: "Dashboard", path: "dashboard" },
  { label: "Schedule", path: "schedule" },
  { label: "Jobs", path: "jobs" },
  { label: "Accounts", path: "accounts" },
  { label: "Communities", path: "communities" },
  { label: "Homes", path: "homes" },
  { label: "Contacts", path: "contacts" },
  { label: "Tasks", path: "tasks" },
  { label: "Files", path: "files" },
  { label: "Settings", path: "settings" },
];

const LeftContainer = ({ handleOnSearch, showSearch, handleOnClickSwitch }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          mr: 1,
          width: "54px",
          height: "28px",
          [theme.breakpoints.down("md")]: {
            width: "32px",
            height: "16px",
            mr: "10px",
          },
        }}
      >
        <img src={logoSmall} alt={en.logo} />
      </Box>
      <Typography
        sx={{
          display: { xxxs: "none", xs: "flex" },
          fontWeight: 700,
          fontSize: 20,
          [theme.breakpoints.down("md")]: {
            fontSize: 16,
          },
        }}
      >
        {en.miScheduling}
      </Typography>
      <MuiDivider
        height={25}
        sx={{
          ml: 2,
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
      />
      <MuiIconButton
        sx={{
          margin: theme.spacing(0, 1),
          [theme.breakpoints.up("xl")]: {
            display: "none",
          },
        }}
        onClick={handleOnClickSwitch}
      >
        <MuiTooltip title={en.switchModule} placement={"bottom"}>
          <Icon path={mdiChevronDown} size={1} />
        </MuiTooltip>
      </MuiIconButton>
      <MuiButton
        title={en.switchModule}
        startIcon={<Icon path={mdiChevronDown} size={1} />}
        sx={{
          fontSize: 12,
          fontWeight: 700,
          borderRadius: "8px",
          ml: 2,
          [theme.breakpoints.down("xl")]: {
            display: "none",
          },
          ":hover": {
            background: alpha(theme.colors.white.primary, 0.15),
          },
        }}
        onClick={handleOnClickSwitch}
      />
      <MuiDivider height={25} sx={{ display: { lg: "none" } }} />
      <MuiIconButton
        sx={{
          display: { lg: "none" },
          marginLeft: 1,
          color: theme.colors.white.primary,
          backgroundColor: showSearch
            ? alpha(theme.colors.white.primary, 0.15)
            : "none",
        }}
        onClick={handleOnSearch}
      >
        <MuiTooltip title={en.search} placement={"bottom"}>
          <Icon path={mdiMagnify} size={1} />
        </MuiTooltip>
      </MuiIconButton>
    </>
  );
};

const RightContainer = ({
  handleClick,
  handleClose,
  showModal,
  handleClickAvatar,
}) => {
  const theme = useTheme();

  return (
    <>
      <MuiIconButton sx={{ mr: 2 }}>
        <MuiTooltip title={en.messages} placement={"bottom"}>
          <Grid>
            <MuiBadge path={mdiCommentTextOutline} content={8} />
          </Grid>
        </MuiTooltip>
      </MuiIconButton>
      <MuiIconButton sx={{ mr: 2 }}>
        <MuiTooltip title={en.notifications} placement={"bottom"}>
          <Grid>
            <MuiBadge path={mdiBellOutline} content={8} />
          </Grid>
        </MuiTooltip>
      </MuiIconButton>
      <MuiIconButton sx={{ display: { xxxs: "none", xxs: "flex" }, mr: 2 }}>
        <MuiTooltip title={en.settings} placement={"bottom"}>
          <Grid>
            <MuiBadge path={mdiCogOutline} content={8} />
          </Grid>
        </MuiTooltip>
      </MuiIconButton>
      <MuiIconButton
        sx={{
          display: { xxs: "none" },
          color: theme.colors.white.primary,
          mr: 2,
        }}
        onClick={handleClick}
      >
        <MuiTooltip title={en.more} placement={"bottom"}>
          <Icon path={mdiPlusBoxOutline} size={1} />
        </MuiTooltip>
      </MuiIconButton>
      <MenuIcons
        anchorEl={showModal}
        open={Boolean(showModal)}
        handleClose={handleClose}
        sx={{
          "& .MuiMenu-list": {
            backgroundColor: theme.colors.blue.secondary,
            color: theme.colors.white.primary,
            border: "1px solid",
            borderColor: theme.colors.blue.secondary,
          },
        }}
      />
      <Avatar
        sx={{
          width: 32,
          height: 32,
          cursor: "pointer",
          fontSize: 12,
          fontWeight: 700,
          backgroundColor: theme.colors.blue.primary,
          boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.03)",
          ":hover": {
            border: "1.5px solid ",
            borderColor: theme.colors.blue.border,
          },
        }}
        onClick={handleClickAvatar}
      >
        AZ
      </Avatar>
    </>
  );
};

const TopBar = () => {
  const theme = useTheme();
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchNavBar, setShowSearchNavBar] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [showPopover, setShowPopover] = useState({
    avatarPop: null,
    switchPop: null,
    thisPagePop: null,
  });

  const handleCollapse = useCallback(() => {
    setShowSearchNavBar((prev) => !prev);
  }, []);

  const handleClick = (event) => {
    setShowModal(event.currentTarget);
  };
  const handleClose = () => {
    setShowModal(null);
  };

  const handleOpenPopover = (event, popType) => {
    setShowPopover((prevState) => {
      return { ...prevState, [popType]: event.currentTarget };
    });
  };
  const handleClosePopover = () => {
    setShowPopover({
      avatarPop: null,
      switchPop: null,
      thisPagePop: null,
    });
  };

  const handleOnSearch = () => {
    setShowSearch(true);
  };

  return (
    <>
      <Collapse style={{ minHeight: "auto" }} in={showSearchNavBar}>
        <AppBar
          position="relative"
          sx={{
            background:
              " conic-gradient(from 89.94deg at 100% 0%, #00A5E2 0deg, #21409A 360deg)",
            boxShadow: "none",
          }}
        >
          <Toolbar
            sx={{
              height: 65,
              "& .MuiToolbar-root,": { padding: theme.spacing(0, 2) },
            }}
          >
            {!showSearch ? (
              <>
                <LeftContainer
                  handleOnSearch={handleOnSearch}
                  showSearch={showSearch}
                  // handleOnClickSwitch={(event) =>
                  //   handleOpenPopover(event, "switchPop")
                  // }
                />
                <Search
                  sx={{ display: { xxxs: "none", lg: "flex" } }}
                  onClose={() => setShowSearch(false)}
                  // handleOnClickThisPage={(event) =>
                  //   handleOpenPopover(event, "thisPagePop")
                  // }
                />
                <Box
                  sx={{ display: { xxxs: "flex", lg: "none" }, flexGrow: 1 }}
                />
                <RightContainer
                  handleClick={handleClick}
                  handleClose={handleClose}
                  showModal={showModal}
                  handleClickAvatar={(event) =>
                    handleOpenPopover(event, "avatarPop")
                  }
                />
              </>
            ) : (
              <Search
                sx={{ display: "flex" }}
                onClose={() => setShowSearch(false)}
                // handleOnClickThisPage={(event) =>
                //   handleOpenPopover(event, "thisPagePop")
                // }
              />
            )}
          </Toolbar>
        </AppBar>
      </Collapse>
      <NavButtons
        items={tabItems}
        collapsed={showSearchNavBar}
        onCollapsed={handleCollapse}
      />
      <MuiPopover
        anchorEl={
          showPopover.avatarPop ??
          showPopover.switchPop ??
          showPopover.thisPagePop
        }
        open={Boolean(
          showPopover.avatarPop ??
            showPopover.switchPop ??
            showPopover.thisPagePop
        )}
        handlePopoverClose={handleClosePopover}
      >
        {showPopover.avatarPop
          ? "Avator pop"
          : showPopover.switchPop
          ? "Switch pop"
          : showPopover.thisPagePop
          ? "Page pop"
          : null}
      </MuiPopover>
    </>
  );
};

export default memo(TopBar);
