import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { mdiChevronLeft, mdiChevronRight, mdiMenuDown, mdiPlus } from "@mdi/js";
import { Button, Divider, IconButton, styled, useTheme } from "@mui/material";
import Icon from "@mdi/react";
import MuiBadge from "./MuiBadge";
import MuiIconButton from "./MuiIconButton";
import MuiTooltip from "./MuiTooltip";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "#F2F7FF",
  },
  "& .MuiTabs-indicator": {
    left: 0,
    width: 6,
    borderRadius: "6px 0px 0px 6px",
    backgroundColor: theme.colors.blue.primary,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.colors.blueGray.primary,
  fontSize: 12,
  fontWeight: 500,
  height: 42,
  boxShadow:
    "0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 7px 1px rgba(0, 0, 0, 0.06)",
  width: "fit-content",
  borderRadius: "50px",
  margin: "8px",
  ":hover": { border: "1.5px solid", borderColor: theme.colors.blue.border },
  "& .MuiButton-startIcon, .MuiButton-endIcon": {
    marginLeft: 0,
    marginRight: 6,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: theme.colors.blueGray.mediumDark,
  fontWeight: 500,
  fontSize: 13,
  minWidth: 160,
  minHeight: 44,
  paddingLeft: "23px",
  paddingRight: "5px",
  justifyContent: "flex-start",
  ":hover": {
    backgroundColor: theme.colors.gray.light,
  },
  "&.Mui-selected": {
    fontWeight: 600,
    color: theme.colors.blue.primary,
    borderRight: "none",
    backgroundColor: theme.colors.gray.light,
    letterSpacing: "0em",
  },
}));

const SideTabBarV2 = ({
  tabItems,
  defaultTabVal,
  isDispatch = false,
  onClickCreateBtn,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const matchPath = (items = []) => {
    const navPath = pathname.split("/")[3] || "";
    const matchItem = items.find(({ path }) => {
      return path === navPath;
    });
    return matchItem?.path || defaultTabVal;
  };

  const selectedTabValue = matchPath(tabItems);

  const getOpenedTabItems = () => {
    return tabItems.map((item) => {
      return (
        <StyledTab
          sx={{ "& .MuiBadge-root": { paddingRight: "10px" } }}
          iconPosition="start"
          key={item.path}
          label={<MuiBadge content={item.content}>{item.label}</MuiBadge>}
          value={item.path}
          component={Link}
          to={item?.path}
          icon={<MuiBadge path={item.icon} size={0.85} />}
          disableFocusRipple
          disableRipple
        />
      );
    });
  };

  const getTabIconItems = () => {
    return tabItems.map((item) => {
      return (
        <StyledTab
          sx={{ minWidth: open ? 160 : 65 }}
          key={item.path}
          value={item.path}
          component={Link}
          to={item?.path}
          icon={
            <MuiBadge content={item.content}>
              <MuiTooltip title={item.title} placement={"right"}>
                <Icon path={item.icon} size={0.85} />
              </MuiTooltip>
            </MuiBadge>
          }
          disableFocusRipple
          disableRipple
        />
      );
    });
  };

  return (
    <Box
      sx={{
        borderRight: 1,
        borderColor: "divider",
        height: "100%",
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isDispatch ? (
          open ? (
            <StyledButton
              onClick={onClickCreateBtn}
              startIcon={
                <Icon
                  path={mdiPlus}
                  size={1}
                  color={theme.colors.blue.primary}
                />
              }
              endIcon={
                <Icon
                  path={mdiMenuDown}
                  size={0.8}
                  color={theme.colors.gray.mediumGray}
                />
              }
            >
              Create
            </StyledButton>
          ) : (
            <MuiTooltip title={"Create New"} placement={"right"}>
              <IconButton
                onClick={onClickCreateBtn}
                sx={{
                  height: 42,
                  width: 42,
                  borderRadius: "50px",
                  alignSelf: "center",
                  margin: "8px",
                  ":hover": {
                    border: "1.5px solid",
                    borderColor: theme.colors.blue.border,
                  },
                  boxShadow:
                    " 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 7px 1px rgba(0, 0, 0, 0.06)",
                }}
              >
                <Icon
                  path={mdiPlus}
                  size={1}
                  color={theme.colors.blue.primary}
                />
              </IconButton>
            </MuiTooltip>
          )
        ) : null}
        <StyledTabs
          sx={{
            paddingTop: isDispatch ? 0 : 2,
          }}
          value={selectedTabValue}
          orientation="vertical"
        >
          {open ? getOpenedTabItems() : getTabIconItems()}
        </StyledTabs>
      </Box>
      <Box>
        <Divider sx={{ margin: "0 4px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: open ? "end" : "center",
            padding: 1,
          }}
        >
          <MuiIconButton
            sx={{
              color: theme.colors.blueGray.mediumDark,
              ":hover": {
                color: theme.colors.blue.blueMedium,
                backgroundColor: theme.colors.gray.light,
              },
            }}
            onClick={handleClick}
          >
            <MuiTooltip placement={"top"} title={open ? "collapse" : "Expand"}>
              <Icon path={open ? mdiChevronLeft : mdiChevronRight} size={1} />
            </MuiTooltip>
          </MuiIconButton>
        </Box>
      </Box>
    </Box>
  );
};

SideTabBarV2.propTypes = {
  tabItems: PropTypes.arrayOf(PropTypes.object),
  defaultTabVal: PropTypes.string.isRequired,
  onClickCreateBtn: PropTypes.func,
  isDispatch: PropTypes.bool,
};

export default React.memo(SideTabBarV2);
