import { memo } from "react";
import { Button, useTheme } from "@mui/material";
import MuiTooltip from "../MuiTooltip";
import { Icon } from "@iconify/react";

const MapLayerButton = ({ icon, variant = "outlined", title, ...props }) => {
  const theme = useTheme();
  return (
    <MuiTooltip title={title} placement="left">
      <Button
        variant={variant}
        sx={{
          width: 30,
          height: 30,
          p: 0,
          borderRadius: "4px",
          boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.03)",
          bgcolor: theme.colors.white.primary,
          border: `1px solid ${theme.colors.white.primary}`,
          color: theme.colors.blueGray.mediumDark,
          fontSize: 16,
          minWidth: "inherit",
          "& svg": {
            width: 20,
            height: 20,
          },

          "&:hover": {
            bgcolor: theme.colors.white.primary,
            borderColor: theme.colors.blue.border,
            //boxShadow: `0px 0px 0px 1px ${theme.colors.blue.border}`,
          },
        }}
        {...props}
      >
        <Icon icon={icon} />
      </Button>
    </MuiTooltip>
  );
};

export default memo(MapLayerButton);
