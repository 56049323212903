import { memo } from "react";
import PropTypes from "prop-types";
import { styled, alpha, IconButton } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.colors.white.primary,
  ":hover": {
    background: alpha(theme.colors.white.primary, 0.15),
  },
  padding: 6,
}));

const MuiIconButton = ({ children, sx, onClick, ...rest }) => {
  return (
    <StyledIconButton
      disableRipple
      disableFocusRipple
      sx={sx}
      onClick={onClick}
      {...rest}
    >
      {children}
    </StyledIconButton>
  );
};

MuiIconButton.propTypes = {
  children: PropTypes.element,
  sx: PropTypes.object,
};

MuiIconButton.defaultProps = {
  sx: {},
};

export default memo(MuiIconButton);
